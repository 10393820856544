/**
 * Mock Services
 * 
 * This file exports mock services that mimic the API structure.
 * Each service corresponds to a feature area and provides methods
 * that match the API endpoints.
 */

import { 
  personnelData, 
  skillsList, 
  rotationsListData, 
  userRequestsData, 
  reviewRequestsData, 
  generateUserCalendarShifts,
  mockRotations,
  employeesData
} from 'data/mock';

// Import wizard service
import { wizardService } from './wizardService';

// Personnel service
export const personnelService = {
  // Get all personnel
  getAll: async () => {
    return personnelData;
  },
  
  // Get personnel by ID
  getById: async (id) => {
    const person = personnelData.find(p => p.id === parseInt(id));
    if (!person) {
      throw new Error('Personnel not found');
    }
    return person;
  },
  
  // Get all skills
  getSkills: async () => {
    return skillsList;
  }
};

// Rotations service
export const rotationsService = {
  // Get all rotations
  getAll: async () => {
    return rotationsListData;
  },
  
  // Get rotation by ID
  getById: async (id) => {
    const rotation = mockRotations[id];
    if (!rotation) {
      throw new Error('Rotation not found');
    }
    
    // Transform the rotation data to include staff and days arrays for the ViewRotation component
    const startDate = new Date(rotation.start_date);
    const endDate = new Date(rotation.end_date);
    const days = [];
    
    // Generate days from start date to end date
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      days.push({
        date: currentDate.toISOString().split('T')[0],
        shifts: [] // This will be populated with shift assignments
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    // Create staff array with 8 staff members
    const staff = Array.from({ length: rotation.number_of_employees || 8 }, (_, i) => ({
      number: i + 1, // Staff number from 1 to 8
      name: `Staff ${i + 1}`
    }));
    
    // Generate random shifts for each day and staff member
    // Shifts are labeled as D (day), E (evening), N (night), or - (off)
    const shiftTypes = ['D', 'E', 'N', '-'];
    
    // Populate shifts for each day for each staff member
    days.forEach((day, dayIndex) => {
      staff.forEach((staffMember, staffIndex) => {
        // Assign a random shift type
        const randomIndex = Math.floor(Math.random() * shiftTypes.length);
        const shiftLabel = shiftTypes[randomIndex];
        
        day.shifts.push({
          label: shiftLabel,
          staffId: staffMember.number
        });
      });
    });
    
    // Generate some random requests for the rotation
    const requestTypes = ['day_off', 'day_shift', 'night_shift'];
    const statusTypes = ['pending', 'approved', 'rejected'];
    const requests = [];
    
    // Create 3-5 random requests
    const numRequests = Math.floor(Math.random() * 3) + 3;
    for (let i = 0; i < numRequests; i++) {
      // Select random day from the rotation period
      const randomDayIndex = Math.floor(Math.random() * days.length);
      const randomDay = days[randomDayIndex];
      
      // Select random staff member
      const randomStaffIndex = Math.floor(Math.random() * staff.length);
      const randomStaff = staff[randomStaffIndex];
      
      requests.push({
        id: i + 1,
        employeeId: randomStaff.number,
        employeeName: randomStaff.name,
        type: requestTypes[Math.floor(Math.random() * requestTypes.length)],
        date: randomDay.date,
        status: statusTypes[Math.floor(Math.random() * statusTypes.length)]
      });
    }
    
    return {
      ...rotation,
      staff,
      days,
      name: rotation.rotation_id,
      isCurrent: rotation.id === 1, // Assuming rotation with id 1 is the current one
      planningHorizon: rotation.planning_horizon,
      admin: employeesData.find(emp => emp.id === rotation.requester_id)?.first_name + ' ' + 
             employeesData.find(emp => emp.id === rotation.requester_id)?.last_name,
      dateCreated: new Date(new Date(rotation.start_date).getTime() - 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      approvedDate: new Date(new Date(rotation.start_date).getTime() - 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      requests: requests,
      startDate: rotation.start_date,
      endDate: rotation.end_date
    };
  },
  
  // Create a new rotation
  create: async (data) => {
    // In a real API, this would create a new rotation
    return {
      ...data,
      id: Math.max(...rotationsListData.map(r => r.id)) + 1,
      dateCreated: new Date().toISOString().split('T')[0]
    };
  },
  
  // Update an existing rotation
  update: async (id, data) => {
    // In a real API, this would update an existing rotation
    return {
      ...data,
      id: parseInt(id)
    };
  }
};

// User requests service
export const userRequestsService = {
  // Get all user requests
  getAll: async () => {
    return userRequestsData;
  },
  
  // Create a new user request
  create: async (data) => {
    // Generate a new ID for the request
    const newId = Math.max(...userRequestsData.map(r => r.id)) + 1;
    const submissionDate = new Date().toISOString().split('T')[0];
    
    // Create the new user request
    const newUserRequest = {
      ...data,
      id: newId,
      status: 'pending',
      submissionDate: submissionDate
    };
    
    // Add to userRequestsData
    userRequestsData.push(newUserRequest);
    
    // Create a corresponding review request for admin
    const newReviewRequest = {
      id: newId,
      type: data.type,
      employee: 'Liam Tremblay', // In a real app, this would be the current user's name
      date: data.date,
      submissionDate: submissionDate,
      status: 'pending'
    };
    
    // Add to reviewRequestsData
    reviewRequestsData.push(newReviewRequest);
    
    return newUserRequest;
  },
  
  // Update an existing user request
  update: async (id, data) => {
    // Find the user request to update
    const requestIndex = userRequestsData.findIndex(r => r.id === parseInt(id));
    
    if (requestIndex !== -1) {
      // Update the user request
      userRequestsData[requestIndex] = {
        ...userRequestsData[requestIndex],
        ...data
      };
      
      // Also update the corresponding review request
      const reviewRequestIndex = reviewRequestsData.findIndex(r => r.id === parseInt(id));
      
      if (reviewRequestIndex !== -1) {
        reviewRequestsData[reviewRequestIndex] = {
          ...reviewRequestsData[reviewRequestIndex],
          ...data
        };
      }
      
      return userRequestsData[requestIndex];
    }
    
    throw new Error('Request not found');
  }
};

// Review requests service
export const reviewRequestsService = {
  // Get all review requests
  getAll: async () => {
    return reviewRequestsData;
  },
  
  // Update an existing review request
  update: async (id, data) => {
    // Find the review request to update
    const requestIndex = reviewRequestsData.findIndex(r => r.id === parseInt(id));
    
    if (requestIndex !== -1) {
      // Update the review request
      reviewRequestsData[requestIndex] = {
        ...reviewRequestsData[requestIndex],
        ...data
      };
      
      // Also update the corresponding user request
      const userRequestIndex = userRequestsData.findIndex(r => r.id === parseInt(id));
      
      if (userRequestIndex !== -1) {
        userRequestsData[userRequestIndex] = {
          ...userRequestsData[userRequestIndex],
          ...data
        };
      }
      
      return reviewRequestsData[requestIndex];
    }
    
    throw new Error('Request not found');
  }
};

// User calendar service
export const userCalendarService = {
  // Get user calendar shifts
  getShifts: async () => {
    return generateUserCalendarShifts();
  }
};

// Export wizard service
export { wizardService }; 