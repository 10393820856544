/**
 * Mock data for Requests
 * Matches backend Request model
 */

export const requestsData = [
  {
    id: 1,
    rotation_id: 'EM-ROTATION-2024-Q1',
    type: 'vacation',
    employee_id: 1, // Liam Tremblay
    date: '2024-02-05'
  },
  {
    id: 2,
    rotation_id: 'EM-ROTATION-2024-Q1',
    type: 'personal',
    employee_id: 2, // Emma Roy
    date: '2024-02-20'
  },
  {
    id: 3,
    rotation_id: 'EM-ROTATION-2024-Q1',
    type: 'sick',
    employee_id: 3, // Noah Bouchard
    date: '2024-02-10'
  },
  {
    id: 4,
    rotation_id: 'SU-ROTATION-2024-Q1',
    type: 'vacation',
    employee_id: 5, // Thomas Anderson
    date: '2024-03-01'
  },
  {
    id: 5,
    rotation_id: 'SU-ROTATION-2024-Q1',
    type: 'personal',
    employee_id: 6, // Sarah Thompson
    date: '2024-02-25'
  },
  {
    id: 6,
    rotation_id: 'PED-ROTATION-2024-Q1',
    type: 'personal',
    employee_id: 7, // Jennifer Clark
    date: '2024-03-10'
  }
];

// Extended request data for UI with additional fields not in the backend model
export const requestsUIData = requestsData.map(request => {
  // Get employee name from the ID for display purposes
  const employeeNames = {
    1: 'Liam Tremblay',
    2: 'Emma Roy',
    3: 'Noah Bouchard',
    4: 'Alexander Lee',
    5: 'Thomas Anderson',
    6: 'Sarah Thompson',
    7: 'Jennifer Clark',
    8: 'Boby Grewal'
  };
  
  // Add UI-specific fields for display
  return {
    ...request,
    employee: employeeNames[request.employee_id],
    status: Math.random() > 0.6 ? 'approved' : Math.random() > 0.5 ? 'rejected' : 'pending',
    submissionDate: new Date(new Date(request.date).getTime() - Math.floor(Math.random() * 14) * 24 * 60 * 60 * 1000).toISOString().split('T')[0]
  };
});

export default requestsData; 