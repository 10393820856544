/**
 * Mock data for Rotation Lines
 * Matches backend RotationLine model
 */

export const rotationLinesData = [
  {
    id: 1,
    rotation_line_id: 'EM-LINE-001',
    rotation_id: 'EM-ROTATION-2024-Q1',
    schedule: JSON.stringify([
      [1, 1, 1, 1, 1, 6, 6],  // Week 1: 5 days on (Day shift), 2 days off
      [6, 6, 2, 2, 2, 2, 2],  // Week 2: 2 days off, 5 days on (Evening shift)
      [2, 6, 6, 3, 3, 3, 3],  // Week 3: 1 day on (Evening), 2 days off, 4 days on (Night shift)
      [3, 6, 6, 6, 1, 1, 1]   // Week 4: 1 day on (Night), 3 days off, 3 days on (Day shift)
    ]),
    statutory_holiday_dates: JSON.stringify([
      '2024-02-19' // Family Day
    ])
  },
  {
    id: 2,
    rotation_line_id: 'EM-LINE-002',
    rotation_id: 'EM-ROTATION-2024-Q1',
    schedule: JSON.stringify([
      [6, 1, 1, 1, 1, 1, 6],  // Week 1: 1 day off, 5 days on (Day shift), 1 day off
      [2, 2, 2, 2, 2, 6, 6],  // Week 2: 5 days on (Evening shift), 2 days off
      [6, 6, 3, 3, 3, 3, 3],  // Week 3: 2 days off, 5 days on (Night shift)
      [6, 6, 6, 1, 1, 1, 1]   // Week 4: 3 days off, 4 days on (Day shift)
    ]),
    statutory_holiday_dates: JSON.stringify([
      '2024-02-19' // Family Day
    ])
  },
  {
    id: 3,
    rotation_line_id: 'SU-LINE-001',
    rotation_id: 'SU-ROTATION-2024-Q1',
    schedule: JSON.stringify([
      [1, 1, 1, 1, 1, 6, 6],  // Week 1: 5 days on (Day shift), 2 days off
      [6, 6, 1, 1, 1, 1, 1],  // Week 2: 2 days off, 5 days on (Day shift)
      [6, 6, 2, 2, 2, 2, 2],  // Week 3: 2 days off, 5 days on (Evening shift)
      [6, 6, 6, 6, 3, 3, 3]   // Week 4: 4 days off, 3 days on (Night shift)
    ]),
    statutory_holiday_dates: JSON.stringify([
      '2024-02-19' // Family Day
    ])
  },
  {
    id: 4,
    rotation_line_id: 'PED-LINE-001',
    rotation_id: 'PED-ROTATION-2024-Q1',
    schedule: JSON.stringify([
      [1, 1, 1, 1, 1, 6, 6],  // Week 1: 5 days on (Day shift), 2 days off
      [2, 2, 2, 2, 6, 6, 6],  // Week 2: 4 days on (Evening shift), 3 days off
      [6, 3, 3, 3, 3, 6, 6],  // Week 3: 1 day off, 4 days on (Night shift), 2 days off 
      [1, 1, 1, 1, 6, 6, 6]   // Week 4: 4 days on (Day shift), 3 days off
    ]),
    statutory_holiday_dates: null
  }
];

export default rotationLinesData; 