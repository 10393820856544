/**
 * Consolidated Mock Data
 * 
 * This file contains all mock data used in the application.
 * It maintains compatibility with existing code while providing model-compatible structures.
 */

// Import all mock data modules
import sitesData from './sites';
import organizationsData from './organizations';
import departmentsData from './departments';
import collectiveAgreementsData from './collective_agreements';
import rotationRulesData from './rotation_rules';
import employeesData, { employeeSkillsData, skillsList } from './employees';
import shiftsData, { formatShiftTime } from './shifts';
import rotationsData from './rotations';
import rotationLinesData from './rotation_lines';
import assignmentsData, { generateCalendarAssignments } from './assignments';
import requestsData, { requestsUIData } from './requests';

// ======================================================
// PERSONNEL DATA (Legacy format)
// ======================================================

export const personnelData = employeesData.map(employee => {
  // Get skills for this employee from employeeSkillsData
  const employeeSkill = employeeSkillsData.find(skill => skill.employee_id === employee.id);
  const skills = employeeSkill ? employeeSkill.skills : [];
  
  // Map to the old format
  return {
    id: employee.id,
    name: `${employee.first_name} ${employee.last_name}`,
    type: employee.id === 4 ? '0.6' : '1.0', // Hardcoded from the original data
    email: employee.email,
    skills: skills
  };
});

// Re-export skillsList which is already in the right format
export { skillsList };

// ======================================================
// ROTATIONS LIST DATA (Legacy format)
// ======================================================

export const rotationsListData = rotationsData.map(rotation => {
  // Get the department name
  const department = departmentsData.find(dept => dept.id === rotation.department_id);
  
  // Get the admin name (requester)
  const admin = employeesData.find(emp => emp.id === rotation.requester_id);
  
  // Derive team from the rotation's primary shift type
  let team;
  if (rotation.rotation_id.includes('NIGHT')) {
    team = 'Night Shift';
  } else if (rotation.rotation_id.includes('DAY')) {
    team = 'Day Shift';
  } else if (rotation.rotation_id.includes('EVENING')) {
    team = 'Evening Shift';
  } else {
    team = department?.department_name === 'Emergency Medicine' ? 'Night Shift' : 
          department?.department_name === 'Surgery' ? 'Day Shift' : 'Evening Shift';
  }
  
  return {
    id: rotation.id,
    dateCreated: new Date(new Date(rotation.start_date).getTime() - 14 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    startDate: rotation.start_date,
    endDate: rotation.end_date,
    department: department?.department_name.split(' ')[0] || 'Unknown',
    team: team,
    admin: admin ? `${admin.first_name} ${admin.last_name}` : 'Unknown'
  };
});

// ======================================================
// USER REQUESTS DATA (Legacy format)
// ======================================================

export const userRequestsData = requestsUIData.slice(0, 2).map(request => {
  const formattedDate = new Date(request.date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric'
  });
  
  return {
    id: request.id,
    type: request.type.charAt(0).toUpperCase() + request.type.slice(1),
    date: request.id === 2 ? 'Thursdays' : formattedDate,
    status: request.status,
    employee: request.employee,
    submissionDate: request.submissionDate
  };
});

// ======================================================
// ADMIN REVIEW REQUESTS DATA (Legacy format)
// ======================================================

export const reviewRequestsData = requestsUIData.slice(0, 3).map(request => {
  const formattedDate = new Date(request.date).toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  
  return {
    id: request.id,
    type: request.id === 2 ? 'Evening Shift' : request.id === 3 ? 'Night Shift' : 'Day off',
    employee: request.employee,
    date: request.id === 2 ? 'Every Thursday' : formattedDate,
    submissionDate: request.submissionDate,
    status: request.id === 1 ? 'pending' : request.id === 2 ? 'approved' : 'rejected'
  };
});

// ======================================================
// USER CALENDAR DATA (Legacy format)
// ======================================================

export const generateUserCalendarShifts = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const shifts = [];
  let id = 1;

  // Get the first Sunday of the month or the first day if it's a Sunday
  let startDate = new Date(firstDay);
  startDate.setDate(firstDay.getDate() - firstDay.getDay());

  // Get the last Saturday of the month or the last day if it's a Saturday
  let endDate = new Date(lastDay);
  if (endDate.getDay() !== 6) {
    endDate.setDate(lastDay.getDate() + (6 - lastDay.getDay()));
  }

  // Generate shifts for each day (randomly for demo)
  let currentDay = new Date(startDate);
  while (currentDay <= endDate) {
    // Randomly assign shifts (30% chance of having a shift)
    if (Math.random() < 0.3) {
      const isDay = Math.random() < 0.5;
      const shiftStart = new Date(currentDay);
      const shiftEnd = new Date(currentDay);
      
      if (isDay) {
        // Day shift: 07:00 to 15:00
        shiftStart.setHours(7, 0, 0);
        shiftEnd.setHours(15, 0, 0);
      } else {
        // Night shift: 23:00 to 07:00 next day
        shiftStart.setHours(23, 0, 0);
        shiftEnd.setDate(shiftEnd.getDate() + 1);
        shiftEnd.setHours(7, 0, 0);
      }

      const shift = {
        id: id++,
        title: isDay ? 'Day Shift' : 'Night Shift',
        start: shiftStart.toISOString(),
        end: shiftEnd.toISOString(),
        backgroundColor: isDay ? '#00d27a' : '#27bcfd',
        borderColor: 'transparent',
        role: Math.random() < 0.5 ? 'Primary' : 'Secondary',
        extendedProps: {
          date: currentDay.toLocaleDateString(),
          shiftType: isDay ? 'Day' : 'Night'
        }
      };
      shifts.push(shift);
    }
    currentDay.setDate(currentDay.getDate() + 1);
  }

  return shifts;
};

// ======================================================
// ROTATION WIZARD DATA (Legacy format)
// ======================================================

// Map collective agreements to wizard unions format
export const wizardUnions = [
  { id: 'sph_pathology', name: 'SPH Pathology' },
  { id: 'vgh_radiology', name: 'VGH Radiology' },
  { id: 'bc_nurses', name: 'BC Nurses Union' },
  { id: 'heu', name: 'Hospital Employees Union' }
];

// Map rotation rules to wizard rules format
export const wizardRules = [
  { id: 'max_consecutive_days', name: 'Maximum Consecutive Days', description: 'No more than 5 consecutive days worked' },
  { id: 'min_rest_between_shifts', name: 'Minimum Rest Between Shifts', description: 'At least 12 hours between shifts' },
  { id: 'weekend_distribution', name: 'Weekend Distribution', description: 'Weekends off distributed equitably' },
  { id: 'holiday_compensation', name: 'Holiday Compensation', description: 'Premium pay for statutory holidays' },
  { id: 'shift_rotation', name: 'Shift Rotation', description: 'Forward rotation of shifts (day to evening to night)' },
  { id: 'overtime_rules', name: 'Overtime Rules', description: 'Time and a half after 8 hours, double time after 12 hours' }
];

export const wizardRotationTypes = [
  { id: 'line', name: 'Line Rotation' },
  { id: 'block', name: 'Block Rotation' }
];

export const wizardWeekStartOptions = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
];

// Map shifts to wizard shifts format
export const wizardDefaultShifts = shiftsData.filter(shift => shift.id <= 4).map(shift => {
  const startTime = shift.start_time ? shift.start_time.substring(0, 5) : '';
  const endTime = shift.end_time ? shift.end_time.substring(0, 5) : '';
  
  return {
    id: shift.name.toLowerCase(),
    name: shift.name,
    startTime: startTime,
    endTime: endTime,
    color: shift.color,
    enabled: true
  };
});

export const wizardDefaultStaffing = {
  fullTime: { count: 5, minHours: 38, maxHours: 38 },
  partTime: { count: 3, minHours: 25, maxHours: 25 },
  casual: { count: 4, minHours: 15, maxHours: 15 }
};

export const wizardHolidays = [
  { id: 1, date: '2024-01-01', name: 'New Year\'s Day' },
  { id: 2, date: '2024-02-19', name: 'Family Day' },
  { id: 3, date: '2024-03-29', name: 'Good Friday' },
  { id: 4, date: '2024-05-20', name: 'Victoria Day' },
  { id: 5, date: '2024-07-01', name: 'Canada Day' },
  { id: 6, date: '2024-08-05', name: 'BC Day' },
  { id: 7, date: '2024-09-02', name: 'Labour Day' },
  { id: 8, date: '2024-10-14', name: 'Thanksgiving' },
  { id: 9, date: '2024-11-11', name: 'Remembrance Day' },
  { id: 10, date: '2024-12-25', name: 'Christmas Day' },
  { id: 11, date: '2024-12-26', name: 'Boxing Day' }
];

// Map request data to wizard request format
export const wizardRequests = [
  { id: 1, employeeId: 1, employeeName: 'Liam Tremblay', type: 'day_off', date: '2024-02-15', status: 'pending' },
  { id: 2, employeeId: 2, employeeName: 'Emma Roy', type: 'day_shift', date: '2024-02-20', status: 'approved' },
  { id: 3, employeeId: 3, employeeName: 'Noah Bouchard', type: 'night_shift', date: '2024-02-25', status: 'pending' },
  { id: 4, employeeId: 4, employeeName: 'Alexander Lee', type: 'day_off', date: '2024-03-05', status: 'rejected' }
];

// ======================================================
// Export the new model-compatible data
// ======================================================
export {
  sitesData,
  organizationsData,
  departmentsData,
  collectiveAgreementsData,
  rotationRulesData,
  employeesData,
  employeeSkillsData,
  shiftsData,
  formatShiftTime,
  rotationsData,
  rotationLinesData,
  assignmentsData,
  generateCalendarAssignments,
  requestsData,
  requestsUIData
};

// For backward compatibility with old code calling mockRotations
export const mockRotations = rotationsData;

// Default export for backward compatibility
export default {
  // New model-compatible data
  sites: sitesData,
  organizations: organizationsData,
  departments: departmentsData,
  collectiveAgreements: collectiveAgreementsData,
  rotationRules: rotationRulesData,
  employees: employeesData,
  employeeSkills: employeeSkillsData,
  skills: skillsList,
  shifts: shiftsData,
  rotations: rotationsData,
  rotationLines: rotationLinesData,
  assignments: assignmentsData,
  requests: requestsData,
  
  // Helper methods
  formatShiftTime,
  generateCalendarAssignments,
  
  // UI-specific data (extended with display fields)
  ui: {
    requests: requestsUIData
  },
  
  // Legacy format data for backward compatibility
  personnel: personnelData,
  rotationsList: rotationsListData,
  userRequests: userRequestsData,
  reviewRequests: reviewRequestsData,
  generateUserCalendarShifts,
  wizard: {
    unions: wizardUnions,
    rules: wizardRules,
    rotationTypes: wizardRotationTypes,
    weekStartOptions: wizardWeekStartOptions,
    defaultShifts: wizardDefaultShifts,
    defaultStaffing: wizardDefaultStaffing,
    holidays: wizardHolidays,
    requests: wizardRequests
  }
}; 