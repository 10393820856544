/**
 * Mock data for Employees
 * Matches backend Employee model
 */

export const employeesData = [
  {
    id: 1,
    first_name: 'Liam',
    last_name: 'Tremblay',
    email: 'l.tremblay@hospital.ca',
    department_id: 1, // Emergency Medicine
    location_id: 1, // Vancouver General Hospital
    organization_id: 1, // Vancouver Coastal Health
    manager_id: null // Department head
  },
  {
    id: 2,
    first_name: 'Emma',
    last_name: 'Roy',
    email: 'e.roy@hospital.ca',
    department_id: 1, // Emergency Medicine
    location_id: 1, // Vancouver General Hospital
    organization_id: 1, // Vancouver Coastal Health
    manager_id: 1 // Reports to Liam Tremblay
  },
  {
    id: 3,
    first_name: 'Noah',
    last_name: 'Bouchard',
    email: 'n.bouchard@hospital.ca',
    department_id: 3, // ICU
    location_id: 1, // Vancouver General Hospital
    organization_id: 1, // Vancouver Coastal Health
    manager_id: null // Department head
  },
  {
    id: 4,
    first_name: 'Alexander',
    last_name: 'Lee',
    email: 'a.lee@hospital.ca',
    department_id: 6, // Pathology
    location_id: 2, // St. Paul's Hospital
    organization_id: 2, // Providence Health Care
    manager_id: null // Department head
  },
  {
    id: 5,
    first_name: 'Thomas',
    last_name: 'Anderson',
    email: 't.anderson@hospital.ca',
    department_id: 2, // Surgery
    location_id: 3, // BC Children's Hospital
    organization_id: 4, // PHSA
    manager_id: null // Department head
  },
  {
    id: 6,
    first_name: 'Sarah',
    last_name: 'Thompson',
    email: 's.thompson@hospital.ca',
    department_id: 2, // Surgery
    location_id: 1, // Vancouver General Hospital
    organization_id: 1, // Vancouver Coastal Health
    manager_id: 5 // Reports to Thomas Anderson
  },
  {
    id: 7,
    first_name: 'Jennifer',
    last_name: 'Clark',
    email: 'j.clark@hospital.ca',
    department_id: 4, // Pediatrics
    location_id: 3, // BC Children's Hospital
    organization_id: 4, // PHSA
    manager_id: null // Department head
  },
  {
    id: 8,
    first_name: 'Boby',
    last_name: 'Grewal',
    email: 'b.grewal@hospital.ca',
    department_id: 1, // Emergency Medicine
    location_id: 4, // Royal Columbian Hospital
    organization_id: 3, // Fraser Health Authority
    manager_id: null // Department head
  }
];

// Export skill sets as a separate entity (not in the model, but useful for UI)
export const employeeSkillsData = [
  { employee_id: 1, skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'MOR-83', 'SP63', 'IC1-8', 'M-8', 'Autopsy'] },
  { employee_id: 2, skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'SP63', 'PA'] },
  { employee_id: 3, skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'SP63', 'IC1-8', 'Renal'] },
  { employee_id: 4, skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'MOR-83', 'Autopsy', 'PA'] },
  { employee_id: 5, skills: ['ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'IC1-8', 'CYSC-8', 'M-8'] }
];

export const skillsList = [
  'ST-6', 'EM-63', 'ST-7', 'QC-8', 'ACC-8', 'GR 3', 'GR 1', 'GR-2', 'MOR-83', 
  'SP63', 'IC1-8', 'CYSC-8', 'Renal', 'M-8', 'Autopsy', 'PA'
];

export default employeesData; 