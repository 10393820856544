/**
 * Mock data for Shifts
 * Matches backend Shift model
 */

export const shiftsData = [
  {
    id: 1,
    name: 'Day',
    duration: 480, // 8 hours in minutes
    start_time: '07:00:00',
    end_time: '15:00:00',
    forbidden_next: '3', // Cannot be followed by Night shift
    color: '#00d27a' // UI property, not in backend model
  },
  {
    id: 2,
    name: 'Evening',
    duration: 480, // 8 hours in minutes
    start_time: '15:00:00',
    end_time: '23:00:00',
    forbidden_next: null,
    color: '#f5803e' // UI property, not in backend model
  },
  {
    id: 3,
    name: 'Night',
    duration: 480, // 8 hours in minutes
    start_time: '23:00:00',
    end_time: '07:00:00',
    forbidden_next: '1', // Cannot be followed by Day shift
    color: '#27bcfd' // UI property, not in backend model
  },
  {
    id: 4,
    name: 'Long Day',
    duration: 720, // 12 hours in minutes
    start_time: '07:00:00',
    end_time: '19:00:00',
    forbidden_next: '3,5', // Cannot be followed by Night shift or Long Night
    color: '#00b38f' // UI property, not in backend model
  },
  {
    id: 5,
    name: 'Long Night',
    duration: 720, // 12 hours in minutes
    start_time: '19:00:00',
    end_time: '07:00:00',
    forbidden_next: '1,4', // Cannot be followed by Day shift or Long Day
    color: '#3a86ff' // UI property, not in backend model
  },
  {
    id: 6,
    name: 'Off',
    duration: 0,
    start_time: null,
    end_time: null,
    forbidden_next: null,
    color: '#edf2f9' // UI property, not in backend model
  }
];

// Time format conversion utility for UI (not part of backend model)
export const formatShiftTime = (timeString) => {
  if (!timeString) return '';
  
  const [hours, minutes] = timeString.split(':');
  const hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  const formattedHour = hour % 12 || 12;
  
  return `${formattedHour}:${minutes} ${ampm}`;
};

export default shiftsData; 