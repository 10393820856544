/**
 * Mock data for Departments
 * Matches backend Department model
 */

export const departmentsData = [
  {
    id: 1,
    department_id: 'EM-001',
    department_name: 'Emergency Medicine',
    description: '24/7 acute care emergency services'
  },
  {
    id: 2,
    department_id: 'SU-001',
    department_name: 'Surgery',
    description: 'General and specialized surgical services'
  },
  {
    id: 3,
    department_id: 'ICU-001',
    department_name: 'Intensive Care Unit',
    description: 'Critical care for severely ill patients'
  },
  {
    id: 4,
    department_id: 'PED-001',
    department_name: 'Pediatrics',
    description: 'Medical care for infants, children and adolescents'
  },
  {
    id: 5,
    department_id: 'RAD-001',
    department_name: 'Radiology',
    description: 'Diagnostic imaging and interventional radiology services'
  },
  {
    id: 6,
    department_id: 'PATH-001',
    department_name: 'Pathology',
    description: 'Laboratory diagnostic services and tissue examination'
  }
];

export default departmentsData; 