/**
 * Mock data for Rotations
 * Matches backend Rotation model
 */

export const rotationsData = [
  {
    id: 1,
    rotation_id: 'EM-ROTATION-2024-Q1',
    requester_id: 1, // Liam Tremblay
    union_name: 'BC Nurses Union',
    number_of_employees: 10,
    department_id: 1, // Emergency Medicine
    planning_horizon: 4, // 4 weeks
    coverage_requirements: JSON.stringify({
      'Mon': { 'Day': 3, 'Evening': 2, 'Night': 2 },
      'Tue': { 'Day': 3, 'Evening': 2, 'Night': 2 },
      'Wed': { 'Day': 3, 'Evening': 2, 'Night': 2 },
      'Thu': { 'Day': 3, 'Evening': 2, 'Night': 2 },
      'Fri': { 'Day': 3, 'Evening': 3, 'Night': 2 },
      'Sat': { 'Day': 2, 'Evening': 2, 'Night': 2 },
      'Sun': { 'Day': 2, 'Evening': 2, 'Night': 2 }
    }),
    format: 'weekly',
    first_weekday: 0, // Monday
    start_date: '2024-02-01',
    end_date: '2024-02-28',
    department_name: 'Emergency Medicine',
    rule_set_id: 'BCNU-RULES-001',
    organization_id: 1 // Vancouver Coastal Health
  },
  {
    id: 2,
    rotation_id: 'SU-ROTATION-2024-Q1',
    requester_id: 6, // Sarah Thompson
    union_name: 'BC Nurses Union',
    number_of_employees: 8,
    department_id: 2, // Surgery
    planning_horizon: 4, // 4 weeks
    coverage_requirements: JSON.stringify({
      'Mon': { 'Day': 4, 'Evening': 2, 'Night': 1 },
      'Tue': { 'Day': 4, 'Evening': 2, 'Night': 1 },
      'Wed': { 'Day': 4, 'Evening': 2, 'Night': 1 },
      'Thu': { 'Day': 4, 'Evening': 2, 'Night': 1 },
      'Fri': { 'Day': 4, 'Evening': 2, 'Night': 1 },
      'Sat': { 'Day': 2, 'Evening': 1, 'Night': 1 },
      'Sun': { 'Day': 2, 'Evening': 1, 'Night': 1 }
    }),
    format: 'weekly',
    first_weekday: 0, // Monday
    start_date: '2024-02-15',
    end_date: '2024-03-15',
    department_name: 'Surgery',
    rule_set_id: 'BCNU-RULES-001',
    organization_id: 1 // Vancouver Coastal Health
  },
  {
    id: 3,
    rotation_id: 'PED-ROTATION-2024-Q1',
    requester_id: 7, // Jennifer Clark
    union_name: 'BC Nurses Union',
    number_of_employees: 6,
    department_id: 4, // Pediatrics
    planning_horizon: 4, // 4 weeks
    coverage_requirements: JSON.stringify({
      'Mon': { 'Day': 2, 'Evening': 2, 'Night': 1 },
      'Tue': { 'Day': 2, 'Evening': 2, 'Night': 1 },
      'Wed': { 'Day': 2, 'Evening': 2, 'Night': 1 },
      'Thu': { 'Day': 2, 'Evening': 2, 'Night': 1 },
      'Fri': { 'Day': 2, 'Evening': 2, 'Night': 1 },
      'Sat': { 'Day': 1, 'Evening': 1, 'Night': 1 },
      'Sun': { 'Day': 1, 'Evening': 1, 'Night': 1 }
    }),
    format: 'weekly',
    first_weekday: 0, // Monday
    start_date: '2024-03-01',
    end_date: '2024-03-31',
    department_name: 'Pediatrics',
    rule_set_id: 'BCNU-RULES-001',
    organization_id: 4 // PHSA
  }
];

export default rotationsData;
